export const heroDataObj = (headingCopy, subHeadingCopy) => {
    const heroProps = {
        stylingOptions: {
            textShade: "Dark",
            textAlignment: "Left",
            spacingBottom: "M",
            spacingTop: "M",
            bgGradient: "Brand Clean Gradient Reverse",
            bgImage: [
                {
                    public_id: "hero/PR_hero_bg_rckufq",
                    format: "png",
                    width: 374,
                    height: 420,
                },
            ],
            bgPosition: "Right",
            bgSize: "Contain",
            bgImageStyles: {
                backgroundPosition: "right",
                backgroundSize: "auto",
                position: "absolute",
            },
        },
        heroImage: null,
        heroHeading: {
            raw: {
                data: {},
                content: [
                    {
                        data: {},
                        content: [{ data: {}, marks: [], value: headingCopy, nodeType: "text" }],
                        nodeType: "heading-1",
                    },
                ],
                nodeType: "document",
            },
        },
        heroSubHeading: {
            raw: {
                data: {},
                content: [
                    {
                        data: {},
                        content: [
                            {
                                data: {},
                                marks: [],
                                value: subHeadingCopy,
                                nodeType: "text",
                            },
                        ],
                        nodeType: "heading-2",
                    },
                ],
                nodeType: "document",
            },
        },

        imageVertAlignment: "Center",
        imageHorzAlignment: "Right",
    };

    return heroProps;
};
