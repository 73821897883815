export const pressReleasesTranslations = {
    en: {
        heroHeading: "Press releases",
        heroSubHeading: "Get the latest news from the leader in data health",
        metaTitle: "Talend Press Releases",
        metaDescription:
            "Talend is the leader in data integration, data integrity, and data health. Find out how we put trusted data at the center of every business decision",
    },
    "en-GB": {
        heroHeading: "Press releases",
        heroSubHeading: "Get the latest news from the leader in data health",
        metaTitle: "Talend Press Releases",
        metaDescription:
            "Talend is the leader in data integration, data integrity, and data health. Find out how we put trusted data at the center of every business decision",
    },
    fr: {
        heroHeading: "Communiqués de presse",
        heroSubHeading: "Obtenez les dernières nouvelles du leader de la santé des données",
        metaTitle: "Communiqués de presse Talend",
        metaDescription:
            "Talend est le leader mondial de l’intégration, de l'intégrité et de la santé des données. Découvrez comment nous plaçons les données fiables au cœur de chaque décision stratégique",
    },
    de: {
        heroHeading: "Pressemeldungen",
        heroSubHeading: "Holen Sie sich die neuesten Nachrichten vom Anbieter in der Datengesundheit",
        metaTitle: "Talend-Pressemitteilungen",
        metaDescription:
            "Talend gehört zu den Marktführern in den Bereichen Datenintegration, Datenintegrität und Data Health. Erfahren Sie, wie wir vertrauenswürdige Daten in den Mittelpunkt jeder Geschäftsentscheidung stellen",
    },
    it: {
        heroHeading: "Comunicati stampa",
        heroSubHeading: "Ottieni le ultime notizie dal leader nella salute dei dati",
        metaTitle: "Comunicati stampa di Talend",
        metaDescription:
            "Talend è leader nelle tecnologie per l'integrazione, l'integrità e la salute dei dati. Scopri come mettiamo i dati affidabili al centro di tutte le decisioni aziendali",
    },
    ja: {
        heroHeading: "プレスリリース",
        heroSubHeading: "データの健康のリーダーから最新のニュースを入手",
        metaTitle: "Talendプレスリリース",
        metaDescription:
            "Talendは、データ統合、データ整合性、およびデータの健全性のリーダーです。Talendがどのようにして、信頼できるデータをあらゆるビジネス上の意思決定の中心に置くのかをご覧ください",
    },
};

export const newsArticlesTranslations = {
    en: {
        heroHeading: "In the news",
        heroSubHeading: "Catch up on recent news in data and data health",
        metaTitle: "Talend News Articles",
        metaDescription:
            "Find out what people are saying about Talend, the leader in data integration, data integrity, and data health.",
    },
    "en-GB": {
        heroHeading: "In the news",
        heroSubHeading: "Catch up on recent news in data and data health",
        metaTitle: "Talend News Articles",
        metaDescription:
            "Find out what people are saying about Talend, the leader in data integration, data integrity, and data health.",
    },
    fr: {
        heroHeading: "À la une",
        heroSubHeading: "Data et santé des données : toute l'actualité",
        metaTitle: "Articles sur Talend",
        metaDescription:
            "Découvrez ce qui se dit sur Talend, le leader mondial de l'intégration, de l'intégrité et de la santé des données.",
    },
    de: {
        heroHeading: "In den Nachrichten",
        heroSubHeading: "Informieren Sie sich über die neuesten Nachrichten im Bereich Data und Data Health",
        metaTitle: "News-Artikel zu Talend",
        metaDescription:
            "Finden Sie heraus, was die Leute über Talend denken, dem marktführenden Unternehmen in den Bereichen Datenintegration, Datenintegrität und Data Health.",
    },
    it: {
        heroHeading: "Notizie su Talend",
        heroSubHeading: "Scopri le ultime notizie sui dati e sulla Data Health",
        metaTitle: "Articoli su Talend",
        metaDescription:
            "Scopri che cosa dicono di Talend, leader nelle tecnologie per l'integrazione, l'integrità e la salute dei dati.",
    },
    ja: {
        heroHeading: "ニュース",
        heroSubHeading: "データとデータの健全性（Data Health） の最新ニュースをキャッチアップ",
        metaTitle: "Talendニュース記事",
        metaDescription:
            "データ統合、データ整合性、データの健全性のリーダーであるTalendが、どのように評価されているのかをご覧ください。",
    },
};
